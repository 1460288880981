<template>
  <div>
    <x-table
      ref="table"
      title="场景联动"
      :card="true"
      :options="options"
      @rowAdd="rowAdd"
      @rowViewRE="rowViewRE"
      @rowEditRE="rowEditRE"
      @rowDelete="rowDelete"
      @rowStart="rowStart"
      @rowStop="rowStop"
      @rowExecute="rowExecute"
      @search="searchHandle">
    </x-table>
    <b-sidebar
      id="sidebar-right"
      ref="slider"
      idebar-class="sidebar-xl"
      bg-variant="white"
      right
      backdrop
      shadow
      width="85%"
      :visible="visibleFlag"
      @hidden="sideHide">
      <div class="ml-2 mt-1 font-medium-2 x-text-bold text-primary"> {{formType === $x.biz.FormType.ADD ? '新增' : (formType === $x.biz.FormType.VIEW ? '详情' : '编辑') }}</div>
      <scene-linkage-form
        v-if="visibleFlag"
        :form-type="formType"
        :form-data="formData"
        @editSuccess="editSuccess"
      />
    </b-sidebar>
  </div>
</template>
<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  BSidebar,
} from 'bootstrap-vue'
import {
  query, patch, remove, start, stop, execute,
} from '@/api/data-center/scene-linkage'
import sceneLinkageForm from './sceneLinkageForm.vue'

export default {
  components: {
    XTable,
    BSidebar,
    sceneLinkageForm,
  },
  data() {
    return {
      formType: undefined,
      formData: {
        triggers: [],
        actions: [],
      },
      // siderBar显示隐藏
      visibleFlag: false,
      options: {
        // formWidth: 'lg',
        searchFold: true,
        lableVertical: true,
        exportBtn: false,
        printBtn: false,
        formTip: '可自定义输入ID，请确保ID唯一，ID为空时由服务端自动生成！',
        actions: [
          { action: 'viewRE', name: '查看', icon: 'EyeIcon' },
          { action: 'editRE', name: '编辑', icon: 'EditIcon' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon' },
          { action: 'start', name: '启动', icon: 'PlayIcon' },
          { action: 'stop', name: '停止', icon: 'PauseIcon' },
          { action: 'execute', name: '执行', icon: 'ToolIcon' },
        ],
        actionFilter: (action, row) => {
          if (row.state.value === 'stopped') {
            return action === 'viewRE' || action === 'editRE' || action === 'delete' || action === 'start'
          }
          return action === 'viewRE' || action === 'editRE' || action === 'stop' || action === 'execute'
        },
        columns: [{
          label: 'ID',
          labelShow: true,
          tip: '可自定义输入ID，请确保ID唯一，ID为空时由服务端自动生成！',
          prop: 'id',
          editDisable: true,
          searchShow: false,
        }, {
          label: '规则名称',
          labelShow: true,
          prop: 'name',
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入规则名称',
          },
        }, {
          label: '规则描述',
          labelShow: true,
          prop: 'description',
          type: 'textarea',
          searchShow: false,
        }, {
          label: '状态',
          labelShow: true,
          prop: 'state',
          type: 'select',
          addShow: false,
          editShow: false,
          viewShow: false,
          rowSelectDot: true,
          selectVariant: prop => {
            if (prop.value === 'stopped') {
              return 'text-danger'
            }
            return 'text-success'
          },
          dictData: [{ text: '已停止', value: 'stopped' }, { text: '已启动', value: 'started' }],
          getDictLabel: option => option.label,
          props: { label: 'text', value: 'value' },
          searchShow: true,
        },
        ],
      },
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    sideHide() {
      this.visibleFlag = false
      this.formData = {
        actions: [],
        triggers: [],
      }
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    // 执行
    rowExecute(data, done) {
      execute(data.id).then(() => {
        done()
      })
    },
    rowAdd(formData, done) {
      patch(formData).then(() => {
        done()
      })
    },
    rowEditRE(row) {
      this.formType = this.$x.biz.FormType.EDIT
      this.formData = this._.cloneDeep(row)
      this.visibleFlag = true
    },
    rowViewRE(row) {
      this.formType = this.$x.biz.FormType.VIEW
      this.formData = row
      this.visibleFlag = true
    },
    rowStart(data, done) {
      start(data.id).then(() => {
        done()
      })
    },
    rowStop(data, done) {
      stop(data.id).then(() => {
        done()
      })
    },
    editSuccess() {
      this.$xtoast.success('编辑成功！')
      this.$refs.slider.hide()
      this.formData = {
        actions: [],
        triggers: [],
      }
      this.$refs.table.__searchHandle()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
